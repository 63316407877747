<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex justify-center align-center h-full pa-16">
              <v-img
                contain
                max-width="60%"
                :src="require('@/assets/images/illustrations/register.png')"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    Simpler life starts here 🚀
                  </p>
                  <p class="mb-2">
                    Make your paperwork paperless!
                  </p>
                  <v-alert
                    v-if="!!invitationToken && organization"
                    dense
                    text
                    color="primary"
                    class="mt-5"
                  >
                    <p class="font-weight-semibold mb-1">
                      You are invited to join {{ organization }}!
                    </p>
                    <p class="text-sm mb-0">
                      Please fill in the missing fields to complete your registration.
                    </p>
                  </v-alert>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="registerForm"
                    @submit.prevent="register"
                  >
                    <v-text-field
                      v-model="name"
                      outlined
                      label="Name"
                      :error-messages="errorMessages.name"
                      :rules="[validators.required]"
                      placeholder="Name"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="nickname"
                      outlined
                      label="Nickname"
                      :error-messages="errorMessages.nickname"
                      :rules="[validators.required]"
                      placeholder="Nickname"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      outlined
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      label="Email"
                      placeholder="Email"
                      hide-details="auto"
                      class="mb-6"
                      :disabled="!!invitationToken"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :rules="[validators.required]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <!-- divider -->
                    <v-card-text class="d-flex align-center mt-2">
                      <v-divider></v-divider>
                      <span class="mx-5">AND</span>
                      <v-divider></v-divider>
                    </v-card-text>

                    <v-text-field
                      v-model="organization"
                      outlined
                      label="Organization"
                      :error-messages="errorMessages.organization"
                      :rules="organizationRules"
                      placeholder="Organization"
                      hide-details="auto"
                      hint="You can change this later."
                      class="mb-6"
                      :disabled="!!invitationToken"
                    ></v-text-field>

                    <v-text-field
                      v-model="contact"
                      outlined
                      label="Contact Number"
                      :error-messages="errorMessages.contact"
                      :rules="contactRules"
                      placeholder="Contact Number"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-btn
                      v-if="!!invitationToken"
                      block
                      color="primary"
                      class="mt-6"
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="acceptInvitation()"
                    >
                      Accept Invitation
                    </v-btn>
                    <v-btn
                      v-else
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Sign Up
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    Already have an account?
                  </p>
                  <router-link :to="{name:'auth-login'}">
                    Sign in instead
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import {
  required,
  emailValidator,
  alphaValidator,
} from '@core/utils/validation'
import {
  ref,
  getCurrentInstance,
  inject,
  onMounted,
} from '@vue/composition-api'
import { axios } from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import encryptionService from '@/services/encryption.service'

export default {
  setup() {
    // Initializers
    const registerForm = ref(null)
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    const vm = getCurrentInstance().proxy
    const { router, route } = useRouter()
    const invitationToken = route.value.query.invitation_token

    // Properties
    const isPasswordVisible = ref(false)
    const name = ref(null)
    const nickname = ref(null)
    const email = ref(null)
    const password = ref(null)
    const organization = ref(null)
    const contact = ref(null)
    const errorMessages = ref([])
    const isLoading = ref(false)

    // Validators
    const organizationRules = [
      v => !!v || 'This field is required',
      v => /^[a-zA-Z]/.test(v) || 'Must start with letter',
      v => /^[0-9a-zA-Z. ]+$/.test(v) || 'Must be alphanumeric',
      v => /[0-9a-zA-Z. ]{4,}/.test(v) || 'Must be at least 4 characters or more',
      v => !/[.]{2,}/.test(v) || 'Must not contain a sequence of .',
      v => /^\S+(?: \S+)*$/.test(v) || 'Must not contain consecutive or end with white-space',
    ]
    const contactRules = [
      v => !!v || 'This field is required',
      v => /^\+[0-9(]/.test(v) || 'Must start with country code',
      v => /^\+[0-9\-().\s]{10,}$/.test(v) || 'Must be valid contact number',
      v => !/[+.\-()]{2,}/.test(v) || 'Must not contain a sequence of special characters',
      v => /^\S+(?: \S+)*$/.test(v) || 'Must not contain consecutive or end with white-space',
    ]

    // Methods
    const fetchInvitedUser = () => {
      axios
        .get('/auth/invitation/new', { params: { invitation_token: invitationToken } })
        .then(response => {
          const { data } = response.data
          email.value = data.email
          organization.value = data.organization.name
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }
    const register = () => {
      isLoading.value = true
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) {
        isLoading.value = false

        return
      }

      axios
        .post('/auth', {
          name: name.value,
          nickname: nickname.value,
          email: email.value,
          password: password.value,
          organization: organization.value,
          contact: contact.value,
        })
        .then(response => {
          const { data } = response.data
          const { permissions, modules } = data

          vm.$ability.update(permissions)
          const encyrptedPermissions = encryptionService.encrypt(JSON.stringify(permissions))
          const encryptedModules = encryptionService.encrypt(JSON.stringify(modules))
          localStorage.setItem('userAbility', encyrptedPermissions)
          localStorage.setItem('organizationModules', encryptedModules)

          delete data.permissions
          store.dispatch('setSettings', data)
          store.dispatch('setOrganization', data.organization)

          router.push('/')
        })
        .catch(error => {
          isLoading.value = false
          errorMessages.value = error.response.data.errors
          snackbarService.error('Something went wrong while creating your account. Please try again later!')
        })
    }
    const acceptInvitation = () => {
      isLoading.value = true
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) {
        isLoading.value = false

        return
      }

      axios
        .get('/auth/invitation/accept', {
          params: {
            invitation_token: invitationToken,
            name: name.value,
            nickname: nickname.value,
            password: password.value,
          },
        })
        .then(response => {
          router.push('/login')
          snackbarService.success(response.data.message)
        })
        .catch(error => {
          isLoading.value = false
          snackbarService.error(error.response.data.errors[0])
        })
    }

    // Mounted
    onMounted(() => {
      if (invitationToken) fetchInvitedUser()
    })

    return {
      // Initializers
      registerForm,
      invitationToken,

      // Properties
      isPasswordVisible,
      name,
      nickname,
      email,
      password,
      organization,
      contact,
      errorMessages,
      isLoading,

      // Validators
      validators: {
        required,
        emailValidator,
        alphaValidator,
      },
      organizationRules,
      contactRules,

      // Others
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Methods
      register,
      acceptInvitation,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
